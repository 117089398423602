import TextInput from 'core/components/Input/TextInput';
import { Controller } from 'react-hook-form';
import { RegisterInputForm } from 'authentication/hooks/useRegister';

const NameInput = ({ control, errors }: RegisterInputForm): JSX.Element => {
    return (
        <Controller
            name="name"
            control={control}
            rules={{ required: 'Nama wajib diisi' }}
            render={({ field }) => (
                <TextInput
                    label="Nama Lengkap"
                    placeholder="Masukkan nama lengkap Anda"
                    {...field}
                    error={errors.name}
                />
            )}
        />
    );
};

export default NameInput;
