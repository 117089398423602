import OAuth2Login from 'react-simple-oauth2-login';
import { useGoogleLoginMutation } from 'authentication/redux/api/authApi';
import LuminaButton from 'core/components/Button';
import GoogleIcon from 'core/components/Icon/GoogleIcon';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { isNotNullAndNotUndefined } from 'core/utils';
import useTracking from 'core/hooks/useTracking';
import LuminaTypography from 'core/components/LuminaTypography';

interface GoogleSuccessPayload {
    access_token: string;
    expires_in: number;
    scope: string;
    token_type: string;
}

const GoogleButton = ({ label }: { label?: string }): JSX.Element => {
    const [loginGoogle, { isLoading }] = useGoogleLoginMutation();
    const track = useTracking('LOGIN PAGE');
    const [isFromWebview, setIsFromWebview] = useState(false);
    const router = useRouter();
    const { utm, code } = router.query;

    const onSuccess = (data: GoogleSuccessPayload): void => {
        loginGoogle({
            social_access_token: data.access_token,
            source: window.localStorage.getItem('source') ?? 'inbound',
            referral_code: isNotNullAndNotUndefined(window.localStorage.getItem('code'))
                ? (window.localStorage.getItem('code') as string)
                : undefined
        });
        track.event('Success Login with Google SSO', {
            source: window.localStorage.getItem('source') ?? 'inbound',
            referral_code: isNotNullAndNotUndefined(window.localStorage.getItem('code'))
                ? (window.localStorage.getItem('code') as string)
                : undefined
        });
    };

    const onError = (): void => {
        toast.error('Gagal masuk dengan Google, mohon coba lagi', {
            hideProgressBar: true,
            position: 'top-center',
            theme: 'colored'
        });
        track.event('Fail Login with Google SSO');
    };

    useEffect(() => {
        // special case for webview
        if (utm) {
            window.localStorage.setItem('source', utm as string);
        }
        if (code) {
            window.localStorage.setItem('code', code as string);
        }
    }, [utm, code]);

    useEffect(() => {
        // special case for webview
        if (window.location.hash) {
            const accessToken = window.location.hash.split('#')[1].split('&')[0].split('=')[1];
            loginGoogle({
                social_access_token: accessToken,
                source: window.localStorage.getItem('source') ?? 'inbound',
                referral_code: isNotNullAndNotUndefined(window.localStorage.getItem('code'))
                    ? (window.localStorage.getItem('code') as string)
                    : undefined
            });
        }

        if (window.navigator.userAgent) {
            if (window.navigator.userAgent.includes('Lumina')) {
                setIsFromWebview(true);
            }
        }
    }, [window]);

    return (
        <OAuth2Login
            authorizationUrl="https://accounts.google.com/o/oauth2/v2/auth"
            responseType="token"
            clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID as string}
            redirectUri={`${window.location.origin}`}
            onSuccess={onSuccess}
            isCrossOrigin={isFromWebview}
            extraParams={{
                prompt: 'select_account'
            }}
            scope={'profile email'}
            onFailure={onError}
            render={({ onClick }: { onClick: () => void }) => (
                <LuminaButton
                    variant="outlined"
                    onClick={onClick}
                    innerClassName="!border-greyscale-300">
                    <div className="w-full flex justify-center gap-2 py-1 ">
                        {isLoading ? (
                            <CircularProgress size={20} color="success" />
                        ) : (
                            <GoogleIcon />
                        )}
                        <LuminaTypography variant="16-bold" className="text-[#40474D]">
                            {label ?? 'Masuk dengan Google'}
                        </LuminaTypography>
                    </div>
                </LuminaButton>
            )}
        />
    );
};

export default GoogleButton;
