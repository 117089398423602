import TextInput from 'core/components/Input/TextInput';
import { Controller } from 'react-hook-form';
import { RegisterInputForm } from 'authentication/hooks/useRegister';
import { Tooltip } from '@mui/material';
import { InfoEmpty } from 'iconoir-react';
import { useRouter } from 'next/router';
import { isNotNullAndNotUndefined } from 'core/utils';

const ReferralCodeInput = ({ control, errors }: RegisterInputForm): JSX.Element => {
    const router = useRouter();
    const { code } = router.query;

    const toolTip = (): JSX.Element => {
        return (
            <Tooltip
                title="Masukkan kode referral terkait jika Anda diundang rekan atau terafiliasi organisasi mitra Lumina."
                enterTouchDelay={0}
                leaveTouchDelay={3500}>
                <div className="flex gap-2 items-center w-fit">
                    Kode referral (Opsional)
                    <InfoEmpty className=" text-[11px] text-greyscale-600" />
                </div>
            </Tooltip>
        );
    };
    return (
        <Controller
            name="referral_code"
            control={control}
            render={({ field }) => (
                <TextInput
                    label={toolTip()}
                    placeholder="Masukkan kode"
                    disabled={isNotNullAndNotUndefined(code)}
                    {...field}
                    error={errors.referral_code}
                />
            )}
        />
    );
};

export default ReferralCodeInput;
