import useRegister from 'authentication/hooks/useRegister';
import LuminaButton from 'core/components/Button';
import CircularProgress from '@mui/material/CircularProgress';
import EmailInput from './EmailInput';
import NameInput from './NameInput';
import PasswordInput from './PasswordInput';
import PhoneInput from 'core/components/Input/PhoneInput';
import useTracking from 'core/hooks/useTracking';
import LuminaTypography from 'core/components/LuminaTypography';
import { useState } from 'react';
import Modal from 'core/components/Modal';
import TermsAndConditionsModal from 'authentication/components/TermsAndConditionsModal';
import ReferralCodeInput from './ReferralCodeInput';

const RegisterForm = (): JSX.Element => {
    const { control, errors, watch, handleSubmit, onRegister, isLoading } = useRegister();
    const [isTermsAndConditionsModalOpen, setIsTermsAndConditionsModalOpen] =
        useState<boolean>(false);
    const track = useTracking('COMPANY REGISTRATION');

    return (
        <form className="my-4 flex flex-col gap-2" onSubmit={handleSubmit(onRegister)}>
            <NameInput control={control} errors={errors} />
            <EmailInput control={control} errors={errors} />
            <PhoneInput control={control} errors={errors} placeholder="Masukkan nomor HP Anda" />
            <PasswordInput control={control} errors={errors} watch={watch} />
            <ReferralCodeInput control={control} errors={errors} />
            <LuminaTypography variant="14-regular">
                Dengan mengklik daftar, Anda menyetujui{' '}
                <span
                    aria-hidden
                    className="font-bold text-sky-600 cursor-pointer"
                    onClick={() => {
                        setIsTermsAndConditionsModalOpen(true);
                        track.event('Opens Terms and Conditions Modal Popup');
                    }}>
                    syarat dan ketentuan{' '}
                </span>
                yang berlaku di ATS Lumina.
            </LuminaTypography>
            <LuminaButton
                disabled={isLoading}
                type="submit"
                variant="contained"
                className="mt-2"
                fullWidth>
                {isLoading ? (
                    <CircularProgress size={25} color="success" />
                ) : (
                    <span>Daftar Sekarang</span>
                )}
            </LuminaButton>

            <Modal
                open={isTermsAndConditionsModalOpen}
                setOpen={setIsTermsAndConditionsModalOpen}
                content={<TermsAndConditionsModal setOpen={setIsTermsAndConditionsModalOpen} />}
            />
        </form>
    );
};

export default RegisterForm;
