import TextInput from 'core/components/Input/TextInput';
import { Controller } from 'react-hook-form';
import { RegisterInputForm } from 'authentication/hooks/useRegister';

const EmailInput = ({ control, errors }: RegisterInputForm): JSX.Element => {
    return (
        <Controller
            name="email"
            control={control}
            rules={{ required: 'Email wajib diisi' }}
            render={({ field }) => (
                <TextInput
                    label="Email Perusahaan"
                    placeholder="Masukkan email berdomain perusahaan Anda"
                    {...field}
                    type="email"
                    error={errors.email}
                />
            )}
        />
    );
};

export default EmailInput;
