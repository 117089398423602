import { useRegisterUserMutation } from 'authentication/redux/api/authApi';
import useTracking from 'core/hooks/useTracking';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useForm, Control, FieldErrors, UseFormWatch, UseFormSetValue } from 'react-hook-form';

export type RegisterInputForm = {
    control: Control<RegisterInputData, any>;
    errors: FieldErrors<RegisterInputData>;
    disabled?: boolean;
    watch?: UseFormWatch<RegisterInputData>;
    setValue?: UseFormSetValue<RegisterInputData>;
};

const useRegister = () => {
    const [isShowPassword, setShowPassword] = useState<boolean>(false);
    const [isShowConfirmationPassword, setShowConfirmationPassword] = useState<boolean>(false);
    const [register, { isLoading }] = useRegisterUserMutation();
    const router = useRouter();
    const track = useTracking('COMPANY REGISTRATION');
    const { utm, code } = router.query;

    const onRegister = (data: RegisterInputData): void => {
        register({
            ...data,
            source: (utm as string) ?? 'inbound'
        });

        track.event('Click Register Button', {
            Email: data.email,
            Phone: data.phone_number,
            FullName: data.name,
            ReferralCode: code as string,
            Source: (utm as string) ?? 'inbound'
        });
    };

    const {
        handleSubmit,
        control,
        watch,
        formState: { errors }
    } = useForm({
        defaultValues: {
            referral_code: (code as string) ?? ''
        } as RegisterInputData
    });

    return {
        onRegister,
        handleSubmit,
        control,
        errors,
        watch,
        isShowPassword,
        setShowPassword,
        isShowConfirmationPassword,
        setShowConfirmationPassword,
        isLoading
    };
};

export default useRegister;
