import RegisterForm from './RegisterForm';
import { Cancel } from 'iconoir-react';
import LuminaTypography from 'core/components/LuminaTypography';
import useTracking from 'core/hooks/useTracking';

const RegisterFormSection = ({ setOpen }: ComponentWithSetOpenProps): JSX.Element => {
    const track = useTracking('COMPANY REGISTRATION');

    return (
        <section className="w-full max-h-screen overflow-y-auto p-6 md:max-w-xl text-greyscale-900 bg-white rounded-xl relative">
            <LuminaTypography variant="16-bold" className="md:hidden">
                Daftar dengan Email
            </LuminaTypography>
            <RegisterForm />
            <button
                onClick={() => {
                    setOpen(false);
                    track.event('Closes Register with Email Modal Popup');
                }}
                className="absolute top-8 right-8">
                <Cancel />
            </button>
        </section>
    );
};

export default RegisterFormSection;
