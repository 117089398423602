import LuminaTypography from 'core/components/LuminaTypography';
import BannerCarousel from 'core/components/BannerCarousel';

const LoginCarousel = (): JSX.Element => {
    return (
        <BannerCarousel
            indicatorColor="bg-greyscale-300"
            indicatorPosition="start"
            indicatorClasses="!px-8 !py-12 lg:!p-16 xl:!p-24"
            circleIndicator
            bannerFullWidth
            withJSXCarousel
            tracker={{
                domain: 'VOUCHER',
                domainLabel: 'VOUCHER'
            }}
            data={[
                {
                    banner_jsx: (
                        <div
                            className="w-full h-full flex justify-start items-end overflow-hidden px-8 py-12 lg:p-16 xl:p-24 !bg-cover !bg-no-repeat"
                            style={{
                                background:
                                    "linear-gradient(180.02deg, rgba(255, 255, 255, 0) 0.02%, #090500 106.7%), url('https://djhutyrz7bno6.cloudfront.net/assets/ats/carousels/login-1.jpg')"
                            }}>
                            <div className="flex flex-col items-start justify-start max-w-3xl gap-1 md:gap-3 text-left pb-8 md:pb-12">
                                <LuminaTypography
                                    desktopVariant="48-bold"
                                    mobileVariant="20-bold"
                                    className="text-sky-50 z-0">
                                    Tes dan skrining otomatis
                                </LuminaTypography>
                                <LuminaTypography
                                    desktopVariant="24-regular"
                                    mobileVariant="14-regular"
                                    className="text-sky-50 z-0">
                                    Temukan kandidat yang tepat dengan lebih efisien melalui proses
                                    screening yang terotomasi.
                                </LuminaTypography>
                            </div>
                        </div>
                    )
                },
                {
                    banner_jsx: (
                        <div
                            className="w-full h-full flex justify-start items-end overflow-hidden  px-8 py-12 lg:p-16 xl:p-24 !bg-cover"
                            style={{
                                background:
                                    "linear-gradient(180.02deg, rgba(255, 255, 255, 0) 0.02%, #090500 106.7%), url('https://djhutyrz7bno6.cloudfront.net/assets/ats/carousels/login-2.jpg')"
                            }}>
                            <div className="flex flex-col items-start justify-start max-w-3xl gap-1 md:gap-3 text-left pb-8 md:pb-12">
                                <LuminaTypography
                                    desktopVariant="48-bold"
                                    mobileVariant="20-bold"
                                    className="text-sky-50 z-0">
                                    Rekrut lebih mudah dengan ATS Mobile
                                </LuminaTypography>
                                <LuminaTypography
                                    desktopVariant="24-regular"
                                    mobileVariant="14-regular"
                                    className="text-sky-50 z-0">
                                    Tidak harus buka laptop, sekarang pakai HP pun bisa rekrut
                                    pekerja.
                                </LuminaTypography>
                            </div>
                        </div>
                    )
                },
                {
                    banner_jsx: (
                        <div
                            className="w-full h-full flex justify-start items-end overflow-hidden  px-8 py-12 lg:p-16 xl:p-24 !bg-cover"
                            style={{
                                background:
                                    "linear-gradient(180.02deg, rgba(255, 255, 255, 0) 0.02%, #090500 106.7%), url('https://djhutyrz7bno6.cloudfront.net/assets/ats/carousels/login-3.jpg')"
                            }}>
                            <div className="flex flex-col items-start justify-start max-w-3xl gap-1 md:gap-3 text-left pb-8 md:pb-12">
                                <LuminaTypography
                                    desktopVariant="48-bold"
                                    mobileVariant="20-bold"
                                    className="text-sky-50 z-0">
                                    Chat langsung kandidat dalam aplikasi
                                </LuminaTypography>
                                <LuminaTypography
                                    desktopVariant="24-regular"
                                    mobileVariant="14-regular"
                                    className="text-sky-50 z-0">
                                    Mudah jangkau kandidat langsung dari aplikasi Lumina, tanpa
                                    ribet
                                </LuminaTypography>
                            </div>
                        </div>
                    )
                },
                {
                    banner_jsx: (
                        <div
                            className="w-full h-full flex justify-start items-end overflow-hidden  px-8 py-12 lg:p-16 xl:p-24 !bg-cover"
                            style={{
                                background:
                                    "linear-gradient(180.02deg, rgba(255, 255, 255, 0) 0.02%, #090500 106.7%), url('https://djhutyrz7bno6.cloudfront.net/assets/ats/carousels/login-4.jpg')"
                            }}>
                            <div className="flex flex-col items-start justify-start max-w-3xl gap-1 md:gap-3 text-left pb-8 md:pb-12">
                                <LuminaTypography
                                    desktopVariant="48-bold"
                                    mobileVariant="20-bold"
                                    className="text-sky-50 z-0">
                                    Fitur lengkap mempercepat rekrutmen
                                </LuminaTypography>
                                <LuminaTypography
                                    desktopVariant="24-regular"
                                    mobileVariant="14-regular"
                                    className="text-sky-50 z-0">
                                    Fitur lengkap dan dukungan penuh dari tim Lumina untuk
                                    mempercepat rekrutmen perusahaan anda.
                                </LuminaTypography>
                            </div>
                        </div>
                    )
                }
            ]}
            delay={5}
        />
    );
};

export default LoginCarousel;
