import TextInput from 'core/components/Input/TextInput';
import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useGetUserReferreeDataQuery, useLoginMutation } from 'authentication/redux/api/authApi';
import CircularProgress from '@mui/material/CircularProgress';
import LuminaButton from 'core/components/Button';
import { EyeEmpty, EyeOff, Mail } from 'iconoir-react';
import useTracking from 'core/hooks/useTracking';
import Link from 'next/link';
import Divider from 'core/components/Divider';
import GoogleButton from './GoogleButton';
import LuminaTypography from 'core/components/LuminaTypography';
import Modal from 'core/components/Modal';
import RegisterFormSection from './Registration/RegisterFormSection';
import { useRouter } from 'next/router';
import { currency } from 'core/utils';

interface LandingFormProps {
    isLoginState: boolean;
    switchState: (state: boolean) => void;
}

const LoginForm = ({ isInitialLogin }: { isInitialLogin?: boolean }): JSX.Element => {
    const router = useRouter();
    const track = useTracking('LOGIN PAGE');
    const [isLoginState, setIsLoginState] = useState<boolean>(isInitialLogin ?? false);

    const switchState = (state: boolean) => {
        setIsLoginState(state);
        track.event('Clicks Login/Register Toggle Button');

        const { utm } = router.query;
        const appendUTM = utm ? `?utm=${utm}` : '';

        if (state) {
            router.push(`/${appendUTM}`, `/${appendUTM}`, { shallow: true });
            track.event('Views ATS Login Page');
        } else {
            router.push(`/${appendUTM}`, `/register${appendUTM}`, { shallow: true });
            track.event('Views ATS Register Page');
        }
    };

    return (
        <div className="w-full flex min-h-screen md:min-h-full md_h:items-start md:items-center md_h:py-8">
            {isLoginState ? (
                <LoginStateForm isLoginState={isLoginState} switchState={switchState} />
            ) : (
                <RegisterStateForm isLoginState={isLoginState} switchState={switchState} />
            )}
        </div>
    );
};

const LoginStateForm = ({ isLoginState, switchState }: LandingFormProps): JSX.Element => {
    const [isShowPassword, setShowPassword] = useState<boolean>(false);

    const {
        handleSubmit,
        control,
        formState: { errors }
    } = useForm({
        defaultValues: {} as LoginInputData
    });
    const [login, { isLoading }] = useLoginMutation();
    const track = useTracking('LOGIN PAGE');

    const onSubmit = (data: LoginInputData): void => {
        login(data);
    };

    return (
        <div className="w-full md_h:pb-4">
            <LuminaTypography
                mobileVariant="20-bold"
                desktopVariant="24-bold"
                className="text-center"
                component="h1">
                Masuk ke ATS Lumina
            </LuminaTypography>
            <LuminaTypography
                mobileVariant="14-regular"
                desktopVariant="16-regular"
                className="text-center m-5"
                component="p">
                Masukkan nomor telepon dan password Anda untuk mengakses lowongan dan pelamar.
            </LuminaTypography>
            <FormStateSwitcher isLoginState={isLoginState} switchState={switchState} />
            <div className="w-full text-center my-2">
                <GoogleButton />
                <div className="flex gap-4 items-center my-6">
                    <Divider />
                    <LuminaTypography variant="16-bold" className="text-greyscale-300">
                        atau
                    </LuminaTypography>
                    <Divider />
                </div>
            </div>
            <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="identifier"
                    control={control}
                    rules={{ required: 'No HP wajib diisi' }}
                    render={({ field }) => (
                        <TextInput
                            startAdornment={<p className="mr-1.5">+62</p>}
                            label="Nomor HP"
                            placeholder="812xxxxx"
                            type="number"
                            {...field}
                            error={errors.identifier}
                        />
                    )}
                />
                <div className="h-1" />
                <Controller
                    name="password"
                    control={control}
                    rules={{ required: 'Password wajib diisi' }}
                    render={({ field }) => (
                        <TextInput
                            label="Password"
                            placeholder="Masukkan password"
                            {...field}
                            type={isShowPassword ? 'text' : 'password'}
                            error={errors.password}
                            endAdornment={
                                isShowPassword ? (
                                    <EyeOff
                                        onClick={() => setShowPassword(false)}
                                        className="text-greyscale-400 cursor-pointer"
                                    />
                                ) : (
                                    <EyeEmpty
                                        onClick={() => setShowPassword(true)}
                                        className="text-greyscale-400 cursor-pointer"
                                    />
                                )
                            }
                        />
                    )}
                />
                <div className="h-2" />
                <div className="flex justify-end">
                    <Link href="/forgot-password">
                        <LuminaTypography
                            variant="12-medium"
                            className="text-sky-600 cursor-pointer"
                            onClick={() => track.event('Clicks Forgot Password')}>
                            Lupa Password
                        </LuminaTypography>
                    </Link>
                </div>
                <div className="h-4" />
                <LuminaButton fullWidth type="submit" variant="contained">
                    {isLoading ? (
                        <CircularProgress size={25} color="success" />
                    ) : (
                        <LuminaTypography variant="20-medium">Masuk</LuminaTypography>
                    )}
                </LuminaButton>
            </form>
        </div>
    );
};

const RegisterStateForm = ({ isLoginState, switchState }: LandingFormProps): JSX.Element => {
    const [isRegisterFormModalOpen, setIsRegisterFormModalOpen] = useState<boolean>(false);
    const track = useTracking('COMPANY REGISTRATION');

    const router = useRouter();
    const { code } = router.query;

    const { data: referralData } = useGetUserReferreeDataQuery(code as string, { skip: !code });

    return (
        <div className="w-full">
            <LuminaTypography
                mobileVariant="20-bold"
                desktopVariant="24-bold"
                className="text-center"
                component="h1">
                {code && referralData
                    ? `${referralData.referrer_name} memberikan Anda voucher Lumina ${currency(
                          referralData.referral_reward_credits
                      )}!`
                    : 'Daftar sebagai perusahaan sekarang!'}
            </LuminaTypography>
            <LuminaTypography
                mobileVariant="14-regular"
                desktopVariant="16-regular"
                className="text-center m-5"
                component="p">
                Mulai pasang lowongan dan cari kandidat <b>gratis</b> di ATS Lumina.
            </LuminaTypography>
            {!code && <FormStateSwitcher isLoginState={isLoginState} switchState={switchState} />}
            <div className="w-full flex flex-col text-center my-2 gap-6">
                <GoogleButton label="Daftar dengan Google" />
                <LuminaButton
                    variant="outlined"
                    onClick={() => {
                        setIsRegisterFormModalOpen(true);
                        track.event('Opens Register with Email Modal Popup');
                    }}
                    innerClassName="!border-greyscale-300">
                    <div className="w-full flex justify-center items-center gap-2 py-1 ">
                        <Mail className="w-6 h-6" />
                        <LuminaTypography variant="16-bold" className="text-[#40474D]">
                            Daftar dengan Email
                        </LuminaTypography>
                    </div>
                </LuminaButton>
            </div>

            <Modal
                open={isRegisterFormModalOpen}
                setOpen={setIsRegisterFormModalOpen}
                content={<RegisterFormSection setOpen={setIsRegisterFormModalOpen} />}
            />
        </div>
    );
};

const FormStateSwitcher = ({ isLoginState, switchState }: LandingFormProps): JSX.Element => {
    return (
        <div className="w-full flex items-center justify-center gap-4 p-1 my-8 rounded-lg bg-greyscale-50 cursor-pointer text-center">
            <LuminaButton
                type="button"
                variant="text"
                onClick={() => switchState(true)}
                className={`w-full rounded-lg ${isLoginState ? 'bg-white' : 'bg-transparent'}`}>
                <LuminaTypography
                    variant="16-bold"
                    component="div"
                    className={isLoginState ? 'text-black' : 'text-greyscale-400'}>
                    Masuk
                </LuminaTypography>
            </LuminaButton>
            <LuminaButton
                type="button"
                onClick={() => switchState(false)}
                variant="text"
                className={`w-full rounded-lg ${!isLoginState ? 'bg-white' : 'bg-transparent'}`}>
                <LuminaTypography
                    variant="16-bold"
                    component="div"
                    className={!isLoginState ? 'text-black' : 'text-greyscale-400'}>
                    Daftar
                </LuminaTypography>
            </LuminaButton>
        </div>
    );
};

export default LoginForm;
