import TextInput, { shouldSame } from 'core/components/Input/TextInput';
import { Controller } from 'react-hook-form';
import { RegisterInputForm } from 'authentication/hooks/useRegister';
import { useState } from 'react';
import { EyeEmpty, EyeOff } from 'iconoir-react';

const PasswordInput = ({ control, errors, watch }: RegisterInputForm): JSX.Element => {
    const [isShowPassword, setShowPassword] = useState<boolean>(false);
    const [isShowConfirmationPassword, setShowConfirmationPassword] = useState<boolean>(false);
    const password = watch && watch('password');

    return (
        <>
            <Controller
                name="password"
                control={control}
                rules={{ required: 'Password wajib diisi' }}
                render={({ field }) => (
                    <TextInput
                        label="Password"
                        placeholder="Masukkan password"
                        {...field}
                        type={isShowPassword ? 'text' : 'password'}
                        error={errors.password}
                        endAdornment={
                            isShowPassword ? (
                                <EyeOff
                                    onClick={() => setShowPassword(false)}
                                    className="text-greyscale-400 cursor-pointer"
                                />
                            ) : (
                                <EyeEmpty
                                    onClick={() => setShowPassword(true)}
                                    className="text-greyscale-400 cursor-pointer"
                                />
                            )
                        }
                    />
                )}
            />
            <Controller
                name="confirmation_password"
                control={control}
                rules={{
                    required: 'Konfirmasi password wajib diisi',
                    validate: {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        shouldSame: (value: string) =>
                            shouldSame(
                                value,
                                password as string,
                                'Password yang Anda masukkan tidak sama'
                            )
                    }
                }}
                render={({ field }) => (
                    <TextInput
                        label="Konfirmasi Password"
                        placeholder="Konfirmasi password"
                        {...field}
                        type={isShowConfirmationPassword ? 'text' : 'password'}
                        error={errors.confirmation_password}
                        endAdornment={
                            isShowConfirmationPassword ? (
                                <EyeOff
                                    onClick={() => setShowConfirmationPassword(false)}
                                    className="text-greyscale-400 cursor-pointer"
                                />
                            ) : (
                                <EyeEmpty
                                    onClick={() => setShowConfirmationPassword(true)}
                                    className="text-greyscale-400 cursor-pointer"
                                />
                            )
                        }
                    />
                )}
            />
        </>
    );
};

export default PasswordInput;
