import LoginCarousel from 'authentication/components/LoginCarousel';
import LoginForm from 'authentication/components/LoginForm';
import useTracking from 'core/hooks/useTracking';
import React, { useState } from 'react';
import useWindowBreakpoints from 'core/hooks/useWindowBreakpoints';
import { Content, Sheet, detents } from 'react-sheet-slide';
import 'react-sheet-slide/style.css';
import { ChatBubbleQuestion, InfoEmpty } from 'iconoir-react';
import Modal from 'core/components/Modal';
import ContactModal from 'authentication/components/ContactModal';

const LoginContainer = ({ isInitialLogin }: { isInitialLogin?: boolean }): JSX.Element => {
    const track = useTracking('LOGIN PAGE');
    const { isMobileBreakpoints } = useWindowBreakpoints();
    const [isContactModalOpen, setIsContactModalOpen] = useState<boolean>(false);

    const openContactModal = () => {
        setIsContactModalOpen(true);
        track.event('Opens Contact Modal Popup');
    };

    const renderMobileView = () => {
        return (
            <div>
                <Sheet
                    open
                    selectedDetent={detents.medium}
                    detents={(props) => [detents.large(props), detents.medium(props)]}
                    useDarkMode={false}
                    useModal={false}
                    scrollingExpands={true}>
                    <Content>
                        <div className="md_h:py-0 py-8 px-4 bg-white">
                            <LoginForm isInitialLogin={isInitialLogin} />
                        </div>
                    </Content>
                </Sheet>

                <div className="w-full flex justify-between items-center absolute top-0 bg-transparent p-4">
                    <img
                        src="https://djhutyrz7bno6.cloudfront.net/assets/full_logo_white.svg"
                        alt="lumina-logo"
                        className="logo-login w-24 h-auto z-10 "
                    />
                    <button type="button" onClick={openContactModal} className="z-10">
                        <InfoEmpty className="text-white" />
                    </button>
                </div>
                <div className="w-full h-screen bg-black bg-auto bg-no-repeat">
                    <div className="w-full h-[50vh]">
                        <LoginCarousel />
                    </div>
                    <div className="w-full h-[50vh]" />
                </div>
            </div>
        );
    };

    const renderDesktopView = () => {
        return (
            <div className="h-screen w-screen flex flex-col-reverse md:flex-row overflow-hidden">
                <div className="w-full h-full 2xl:w-[35%] xl:w-[40%] md:w-[50%] flex 2xl:px-20 xl:px-12 md:px-8 px-4 py-8 flex-col items-start justify-between md_h:overflow-y-auto">
                    <img
                        src="https://djhutyrz7bno6.cloudfront.net/assets/full_logo.svg"
                        alt="lumina-logo"
                        className="logo-login hidden md:flex 2xl:w-24 w-20"
                    />
                    <LoginForm isInitialLogin={isInitialLogin} />
                </div>
                <div className="w-full h-[50vh] 2xl:w-[65%] xl:w-[60%] md:w-[50%] md:min-h-screen bg-black absoulute top-0">
                    <LoginCarousel />
                    <button
                        type="button"
                        className="bg-ocean-600 rounded-md p-2 m-2 absolute bottom-10 right-5"
                        onClick={openContactModal}>
                        <ChatBubbleQuestion className="text-white" />
                    </button>
                </div>
            </div>
        );
    };

    return (
        <>
            {isMobileBreakpoints ? renderMobileView() : renderDesktopView()}

            <Modal
                open={isContactModalOpen}
                setOpen={setIsContactModalOpen}
                content={<ContactModal setOpen={setIsContactModalOpen} />}
            />
        </>
    );
};

export default LoginContainer;
