import { EMAIL_LINK } from 'authentication/constant';
import LuminaButton from 'core/components/Button';
import LuminaTypography from 'core/components/LuminaTypography';
import useTracking from 'core/hooks/useTracking';
import { Cancel } from 'iconoir-react';
import { Mail } from 'iconoir-react';

const ContactModal = ({ setOpen }: ComponentWithSetOpenProps) => {
    const track = useTracking('LOGIN PAGE');

    return (
        <div className="rounded p-8 bg-white w-full md:max-w-3xl relative">
            <button
                onClick={() => {
                    track.event('Closes Contact Modal Popup');
                    setOpen(false);
                }}
                className="absolute top-8 right-8 hidden md:flex">
                <Cancel />
            </button>
            <LuminaTypography variant="16-bold" className="md:hidden">
                Hubungi Kami
            </LuminaTypography>
            <div className="w-full flex justify-center items-center py-6">
                <img
                    src="https://djhutyrz7bno6.cloudfront.net/assets/ats/contact-modal-avatar.png"
                    alt="contact-person"
                    className="w-20"
                />
            </div>
            <div className="text-center flex flex-col text-greyscale-800">
                <LuminaTypography variant="16-bold" className="py-4">
                    Butuh bantuan dari Mba Lumina? Hubungi kami di email ini
                </LuminaTypography>
                <div className="flex items-center cursor-pointer justify-center">
                    <LuminaButton href={`mailto:${EMAIL_LINK}`} variant="text" className="gap-2">
                        <LuminaTypography
                            className="px-1"
                            desktopVariant="14-regular"
                            mobileVariant="16-medium">
                            {EMAIL_LINK}
                        </LuminaTypography>
                        <Mail />
                    </LuminaButton>
                </div>
            </div>

            <div className="w-full mt-6 md:hidden">
                <LuminaButton
                    onClick={() => {
                        track.event('Closes Contact Modal Popup');
                        setOpen(false);
                    }}
                    type="button"
                    variant="contained">
                    Mengerti
                </LuminaButton>
            </div>
        </div>
    );
};

export default ContactModal;
