import LuminaButton from 'core/components/Button';
import LuminaTypography from 'core/components/LuminaTypography';
import useTracking from 'core/hooks/useTracking';
import { TnCItem } from 'dashboard/components/TnCPrompt';
import tnc from 'dashboard/content/tnc.json';

const TermsAndConditionsModal = ({ setOpen }: ComponentWithSetOpenProps) => {
    const track = useTracking('COMPANY REGISTRATION');

    return (
        <div className="w-full max-h-screen overflow-y-auto rounded p-8 bg-white md:max-w-2xl relative">
            <LuminaTypography variant="16-bold" className="flex justify-start md:justify-center">
                Syarat dan Ketentuan
            </LuminaTypography>
            <div className="w-full flex flex-col gap-4 justify-center items-center text-left py-6 ">
                <ol className="w-full rounded-xl p-6 overflow-y-auto list-decimal list-outside">
                    {tnc.map((rule) => (
                        <TnCItem key={rule.content} content={rule.content} />
                    ))}
                </ol>
            </div>
            <div className="w-full">
                <LuminaButton
                    onClick={() => {
                        track.event('Closes Terms and Conditions Modal Popup');
                        setOpen(false);
                    }}
                    type="button"
                    variant="contained">
                    Mengerti
                </LuminaButton>
            </div>
        </div>
    );
};

export default TermsAndConditionsModal;
