import { Typography } from '@mui/material';
import LuminaButton from 'core/components/Button';
import useWindowBreakpoints from 'core/hooks/useWindowBreakpoints';
import { useOnboarding } from 'dashboard/contexts/OnboardingContext';
import tnc from 'dashboard/content/tnc.json';
import useLazyAuthTracking from 'core/hooks/useLazyAuthTracking';
import { useEffect } from 'react';

export const TnCItem = ({ content }: { content: string }): JSX.Element => {
    const baseUrl =
        process.env.NEXT_PUBLIC_NODE_ENV === 'prod'
            ? 'https://lumina.mba'
            : 'https://dev.lumina.mba';
    const path = '/content/penutupan-akun/rekruter';

    const urlToWrap = `${baseUrl}${path}`;

    let dynamicContent = content;
    if (baseUrl === 'https://dev.lumina.mba') {
        dynamicContent = content.replace('https://lumina.mba', baseUrl);
    }

    const isURLPresent = dynamicContent.includes(urlToWrap);

    return (
        <li className="ml-4">
            {isURLPresent ? (
                <Typography variant="14-regular">
                    {dynamicContent.replace(urlToWrap, '')}
                    <a href={urlToWrap} target="_blank" rel="noopener noreferrer">
                        {urlToWrap}
                    </a>
                </Typography>
            ) : (
                <Typography variant="14-regular">{content}</Typography>
            )}
        </li>
    );
};

const TnCPrompt = (): JSX.Element => {
    const { isDesktopBreakpoints } = useWindowBreakpoints();
    const { setConsentTnC } = useOnboarding();
    const track = useLazyAuthTracking('DASHBOARD');

    useEffect(() => {
        track.event('Views Tnc Popup');
    }, []);

    return (
        <div
            className={
                isDesktopBreakpoints
                    ? 'rounded-xl p-8 bg-white w-1/3 overflow-y-auto'
                    : 'rounded-t-3xl w-full bg-white absolute bottom-0 px-4 py-8 overflow-y-auto'
            }
            style={
                isDesktopBreakpoints ? { minWidth: '725px', height: '90vh' } : { height: '90vh' }
            }>
            <div className="w-full flex flex-col justify-center">
                <div className="flex justify-center items-center text-center border-b border-greyscale-100 pb-4">
                    <Typography variant="24-bold">Syarat dan Ketentuan ATS Lumina</Typography>
                </div>
                <div className="w-full my-4 flex justify-center">
                    <img
                        src="https://djhutyrz7bno6.cloudfront.net/assets/ats/ats-header.png"
                        alt="ats-header"
                        className="w-full h-full"
                    />
                </div>
                <ol
                    className="w-full bg-greyscale-50 rounded-xl p-6 overflow-y-auto list-decimal list-outside"
                    style={{ maxHeight: '43vh' }}>
                    {tnc.map((rule) => (
                        <TnCItem key={rule.content} content={rule.content} />
                    ))}
                </ol>
            </div>
            <div className="w-full mt-4">
                <LuminaButton variant="contained" onClick={() => setConsentTnC(false)}>
                    Saya mengerti
                </LuminaButton>
            </div>
        </div>
    );
};

export default TnCPrompt;
