/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import useCompanyUser from 'authentication/hooks/useCompanyUser';
import LuminaBackdrop from 'core/components/LuminaBackdrop';
import useLazyAuthTracking from 'core/hooks/useLazyAuthTracking';
import useWindowBreakpoints from 'core/hooks/useWindowBreakpoints';
import { getIsPersistStorageHydrated } from 'core/redux/selectors/storageConfigSelectors';
import { setPersistStorageHydrated } from 'core/redux/slices/storageConfigSlices';
import { isFinishCompanyOnboarding } from 'dashboard/companyOnboarding/utils';
import { useRouter } from 'next/router';
import { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { rehydration } from 'redux/store';

/**
 * this function is used to decide how to redirect targeted url
 *
 * if user is on mobile devices but access applicant desktop link (/dashboard/applicant?id={id})
 * then user will be redirected into /dashboard/applicant/{id}. this also works if user access on
 * desktop but using applicant mobile link
 */
export const decideApplicantURL = (isDesktop: boolean, target: string): string => {
    if (target.includes('dashboard/applicant')) {
        if (target.includes('id')) {
            if (isDesktop) {
                return target;
            }
            const url = target.split('?');
            const params = new URLSearchParams(url[1]);

            return `${url[0]}/${params.get('id')}`;
        } else {
            if (isDesktop && target.includes('dashboard/applicant/')) {
                const url = target.split('/');
                const baseIndex = url[0] === '' ? 1 : 0;

                return `${url[baseIndex]}/${url[baseIndex + 1]}?id=${url[baseIndex + 2]}`;
            }

            return target;
        }
    }

    return target;
};

const withAnon = (WrappedComponent: React.ComponentType) => {
    return (props: JSX.IntrinsicAttributes & { children?: ReactNode }) => {
        // checks whether we are on client / browser or server.
        if (typeof window !== 'undefined') {
            const { isAuthenticated, onboarding_stage, isSuccessLoadOnboardingData } =
                useCompanyUser();
            const router = useRouter();
            const { target } = router.query as { target: string };
            const { isDesktopBreakpoints } = useWindowBreakpoints();
            const track = useLazyAuthTracking();
            const dispatch = useDispatch();
            const blockingBeforeRehydrate = async (): Promise<void> => {
                await rehydration();
                dispatch(setPersistStorageHydrated(true));
            };
            const isPersistStorageStoreHydrated = useSelector(getIsPersistStorageHydrated);

            useEffect(() => {
                blockingBeforeRehydrate();
            }, []);

            if (isPersistStorageStoreHydrated && onboarding_stage && isAuthenticated) {
                track.identify();
                track.setUser();

                /**
                 * using window.location.href instead of next/router push to trigger hard reload
                 * when user access ATS. this hard reload is necessary for ATS on Mobile to detect
                 * is user already loggedin or not on ATS on Mobile
                 */
                if (
                    router.pathname === '/' ||
                    router.pathname.includes('/reset-password') ||
                    router.pathname.includes('/forgot-password')
                ) {
                    if (target?.length > 0) {
                        window.location.href = decideApplicantURL(isDesktopBreakpoints, target);
                    } else {
                        if (
                            !isFinishCompanyOnboarding(onboarding_stage as CompanyOnboardingStage)
                        ) {
                            window.location.href = '/dashboard/onboarding';
                        } else {
                            window.location.href = '/dashboard/opening';
                        }
                    }
                }

                if (router.pathname.includes('/register')) {
                    window.location.href = '/dashboard/opening';
                }

                return <LuminaBackdrop />;
            }

            if (!isSuccessLoadOnboardingData && isAuthenticated) {
                return <LuminaBackdrop />;
            }

            return <WrappedComponent {...props} />;
        }

        // If we are on server, return null
        return <LuminaBackdrop />;
    };
};

export default withAnon;
